//
// user chat.scss
//

// user chat & non user-chat

.non-user-chat {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;
  }
}

.user-chat {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.user-chat-topbar {
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.btn-user-chat-remove {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.user-chat-nav {
  .nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    color: var(--#{$variable-prefix}gray-600);
  }
  @media (max-width: 575.98px) {
    display: flex;
    justify-content: flex-end;
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-avatar {
    margin: 0 16px 0 0 /*rtl:0 0 0 16px*/;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: $border-color;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .loader-center-list-chat {
    position: fixed;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    &:before {
      content: '';
      position: fixed;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 50%;
      z-index: 9999;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;

    .ctext-wrap {
      display: flex;
      margin-bottom: 10px;
    }

    .ctext-wrap-content-responsived {
      padding: 12px 20px;
      background-color: $chat-primary-bg;
      border-radius: 8px 8px 8px 0;
      color: $white;
      position: relative;
      // max-width: 80%;
      max-width: 550px;
      // min-width: 120px;
      min-width: 25px;
      word-break: break-word;
      white-space: pre-wrap;

      @media (max-width: 1230px) {
        max-width: 400px;
      }

      @media (max-width: 1050px) {
        max-width: 350px;
      }

      @media (max-width: 991px) {
        max-width: 550px;
      }

      @media (max-width: 685px) {
        max-width: 400px;
      }

      @media (max-width: 535px) {
        max-width: 300px;
      }

      @media (max-width: 445px) {
        max-width: 250px;
      }

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-primary-bg;
        border-top-color: $chat-primary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }

      .attached-file {
        .attached-file-avatar {
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }

    .ctext-wrap-content {
      padding: 12px 20px;
      background-color: $chat-primary-bg;
      border-radius: 8px 8px 8px 0;
      color: $white;
      position: relative;
      max-width: 80%;
      min-width: 120px;
      word-break: break-word;
      white-space: pre-wrap;

      @media (max-width: 992px) {
        max-width: 80%;
      }

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-primary-bg;
        border-top-color: $chat-primary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }

      .attached-file {
        .attached-file-avatar {
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }

    .ctext-wrap-content-image {
      padding: 12px 20px;
      background-color: $chat-primary-bg;
      border-radius: 8px 8px 8px 0;
      color: $white;
      position: relative;
      max-width: 80%;
      min-width: 400px;
      word-break: break-word;
      white-space: pre-wrap;

      @media (max-width: 992px) {
        max-width: 80%;
      }

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-primary-bg;
        border-top-color: $chat-primary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }

      .attached-file {
        .attached-file-avatar {
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }

    .ctext-wrap-content-other-file {
      padding: 12px 20px;
      background-color: $chat-primary-bg;
      border-radius: 8px 8px 8px 0;
      color: $white;
      position: relative;
      min-width: 120px;
      max-width: 400px;
      word-break: break-word;
      white-space: pre-wrap;

      @media (max-width: 992px) {
        max-width: 80%;
      }

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-primary-bg;
        border-top-color: $chat-primary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        bottom: -10px;
      }

      .attached-file {
        .attached-file-avatar {
          @media (max-width: 575.98px) {
            display: none;
          }
        }
      }
    }

    .conversation-name {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: var(--#{$variable-prefix}gray-600);

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-time {
      font-size: 12px;
      margin-top: 4px;
      text-align: right;
      color: rgba($white, 0.7);
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        // max-width: 150px;
        max-width: 100%;
      }

      .message-img-link {
        position: absolute;
        right: 10px /*rtl:auto*/;
        left: auto /*rtl:0*/;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: $white;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .right {
    .chat-avatar {
      order: 3;
      margin-right: 0px;
      margin-left: 16px;
    }

    .chat-time {
      text-align: left;
      color: var(--#{$variable-prefix}gray-600);
    }

    .conversation-list {
      float: right;
      text-align: right;

      .ctext-wrap {
        justify-content: flex-end;

        .ctext-wrap-content,
        .ctext-wrap-content-image {
          order: 2;
          background-color: $chat-secondary-bg;
          color: var(--#{$variable-prefix}dark);
          text-align: left;
          max-width: 80%;
          border-radius: 8px 8px 0px 8px;
          word-break: break-word;
          white-space: pre-wrap;

          @media (max-width: 992px) {
            max-width: 80%;
          }

          &:before {
            border: 5px solid transparent;
            border-top-color: $chat-secondary-bg;
            border-right-color: $chat-secondary-bg;
            left: auto;
            right: 0px;
          }
        }

        .ctext-wrap-content-other-file,
        .ctext-wrap-content-responsived {
          order: 2;
          background-color: $chat-secondary-bg;
          color: var(--#{$variable-prefix}dark);
          text-align: left;
          border-radius: 8px 8px 0px 8px;
          word-break: break-word;
          white-space: pre-wrap;

          // @media (max-width: 992px) {
          //   max-width: 80%;
          // }

          &:before {
            border: 5px solid transparent;
            border-top-color: $chat-secondary-bg;
            border-right-color: $chat-secondary-bg;
            left: auto;
            right: 0px;
          }
        }
      }

      .dropdown {
        order: 1;

        .dropdown-menu[style] {
          right: 0 !important;
          left: auto !important;
        }
      }
    }

    .dot {
      background-color: $dark;
    }
  }
}

.chat-conversation {
  height: calc(100vh - 185px);
  @media (max-width: 992px) {
    height: calc(100vh - 220px);
    margin-top: 5px;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    height: calc(100vh - 210px);
    margin-top: 25px;
    margin-bottom: 35px;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 200px);
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.with-loader {
  height: calc(100vh - 225px);
  @media (max-width: 992px) {
    height: calc(100vh - 215px);
    margin-top: 5px;
    margin-bottom: 30px;
  }
  @media (max-width: 991px) {
    height: calc(100vh - 210px);
    margin-top: 25px;
    margin-bottom: 35px;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 200px);
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.file-text-responsived {
  .avatar-sm {
    .avatar-title {
      font-size: 20px;

      @media (max-width: 445px) {
        font-size: 18px;
      }
    }
  }

  h5 {
    max-width: 150px;
    @media (max-width: 445px) {
      max-width: 80px;
    }
  }
}

.img-parent {
  width: 100%;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 220px;
    min-width: 250px;

    @media (max-width: 1230px) {
      min-width: 175px;
      height: 150px;
    }

    @media (max-width: 990px) {
      min-width: 250px;
      height: 220px;
    }

    @media (max-width: 445px) {
      min-width: 150px;
      height: 120px;
    }
  }
}

.chat-input-section {
  background-color: $card-bg;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.chat-input-links {
  .list-inline-item:not(:last-child) {
    margin: 0;
  }
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: -1px;
    background: $white;
    animation: wave 1.3s linear infinite;
    opacity: 0.6;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}

.image-preview {
  img {
    width: 400px;
    height: 350px;
  }
  @media (max-width: 2000px) {
    img {
      width: 450px;
      height: 350px;
    }
  }
  @media (max-width: 2000px) {
    img {
      width: 400px;
      height: 300px;
    }
  }
  // @media (max-width: 1328px) {
  //   img {
  //     width: 240px;
  //     height: 240px;
  //   }
  // }
}

.message-styled {
  text-decoration: underline !important;
}
