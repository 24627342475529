.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #101113;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    position: relative;
    width: 48px;
    height: 48px;
    background: #ffff;
    border-radius: 50%;
    animation: ellipseAnimation 3s linear infinite;
  }
}

@keyframes ellipseAnimation {
  0% {
    border-radius: 50%;
  }

  50% {
    border-radius: 0;
    transform: rotate(180deg);
  }

  62.5% {
    border-radius: 50% 0 0 0;
    transform: rotate(225deg);
  }

  75% {
    border-radius: 50% 50% 0 0;
    transform: rotate(270deg);
  }

  87.5% {
    border-radius: 50% 50% 50% 0;
    transform: rotate(315deg);
  }

  100% {
    border-radius: 50%;
    transform: rotate(360deg);
  }
}
